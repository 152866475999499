import React from "react";
import { set } from "lodash";

import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { DatePicker } from "../DatePicker";
import { HoursSelector } from "../HoursSelector";
import { Checkbox } from "../Checkbox";
import { Input } from "../Input";
import { TimezoneSelector } from "../TimezoneSelector";
import { convertTimeFormatToClockFormat } from "@/utils/dateTimeStringifyHelper";

import { EventDateTime, EventTimeFormat } from "@/types/qr";

import "./styles.scss";
import { PAGES } from "@/internals/constants/pages";

export function EventDateAndTime({
  defaultValue,
  onClick,
}: {
  defaultValue: EventDateTime;
  onClick?: (arg: Partial<EventDateTime>) => void;
}) {
  const { t: translate } = useTranslation(PAGES.QR_EVENT);

  const [format, setTimeFormat] = React.useState<EventTimeFormat>(
    defaultValue?.format || "AM-PM"
  );
  const [isAllDay, setIsAllDay] = React.useState<boolean>(
    defaultValue?.allDay || false
  );

  React.useEffect(() => {
    return onClick && onClick({ format });
  }, [format]);

  React.useEffect(() => {
    onClick && onClick({ allDay: isAllDay });
  }, [isAllDay]);

  const onInputChangeHandler = ({ name, value }) => {
    if (Array.isArray(name)) {
      const newObject = set({}, name, value);
      return onClick && onClick(newObject);
    }

    return onClick && onClick({ [name]: value });
  };

  return (
    <div className="event-date-time">
      <div className="event-date-time__format">
        <Button
          type="button"
          label={translate("_eventTimeAMPMButton")}
          tertiary
          onClick={() => setTimeFormat("AM-PM")}
          disabled={format === "AM-PM"}
        />
        <Button
          type="button"
          label={translate("_eventTime24HoursButton")}
          tertiary
          onClick={() => setTimeFormat("24-HOURS")}
          disabled={format === "24-HOURS"}
        />
      </div>
      <div className="event-date-time__date-time-row">
        <DatePicker
          type="single"
          minDate="today"
          label={translate("_eventTimeStartLabel")}
          inputPlaceholder={translate("_eventTimeStartPlaceholder")}
          defaultDate={
            defaultValue?.start?.date
              ? [new Date(defaultValue?.start?.date)]
              : []
          }
          fluid
          disableMobile
          className={"event-date-time__date-picker"}
          onChangeDate={([date] = []) =>
            onInputChangeHandler({ name: ["start", "date"], value: date })
          }
        />
        {!isAllDay && (
          <HoursSelector
            value={defaultValue?.start?.time}
            name={"start"}
            placeholder={format === "AM-PM" ? "00:00am" : "00:00h"}
            disabled={isAllDay}
            timeFormat={convertTimeFormatToClockFormat(format)}
            onSelectHour={({ name, value }) =>
              onInputChangeHandler({ name: [name, "time"], value })
            }
          />
        )}
      </div>
      <div className="event-date-time__date-time-row">
        <DatePicker
          type="single"
          minDate={
            defaultValue?.start?.date
              ? new Date(defaultValue?.start?.date)
              : "today"
          }
          label={translate("_eventTimeEndLabel")}
          inputPlaceholder={translate("_eventTimeEndPlaceholder")}
          defaultDate={
            defaultValue?.end?.date ? [new Date(defaultValue?.end?.date)] : []
          }
          fluid
          disableMobile
          className={"event-date-time__date-picker"}
          onChangeDate={([date] = []) =>
            onInputChangeHandler({ name: ["end", "date"], value: date })
          }
        />
        {!isAllDay && (
          <HoursSelector
            value={defaultValue?.end?.time}
            name={"end"}
            placeholder={format === "AM-PM" ? "00:00am" : "00:00h"}
            disabled={isAllDay}
            timeFormat={convertTimeFormatToClockFormat(format)}
            onSelectHour={({ name, value }) =>
              onInputChangeHandler({ name: [name, "time"], value })
            }
          />
        )}
      </div>
      <div className="event-date-time__timezone-row">
        <Checkbox
          id={"event-all-day-checkbox"}
          label={translate("_eventTimeAllDayLabel")}
          className="event-date-time__timezone-checkbox"
          defaultValue={isAllDay}
          onChange={(e) => setIsAllDay(e.target.checked)}
        />
        <TimezoneSelector
          placeholder="Select Timezone"
          label={translate("_eventTimeTimezoneLabel")}
          value={defaultValue?.timezone}
          onChange={(timezone) =>
            onInputChangeHandler({ name: "timezone", value: timezone })
          }
        />
      </div>
      <Input
        id="timeActionTitle"
        type="text"
        name="timeActionTitle"
        label={translate("_eventTimeButtonLabel")}
        placeholder={translate("_eventTimeButtonPlaceholder")}
        defaultValue={defaultValue?.actionTitle}
        helper={translate("_eventTimeButtonTooltip")}
        onChange={(value) =>
          onInputChangeHandler({ name: "actionTitle", value })
        }
      />
    </div>
  );
}
