import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { formatToBusinessHoursInput } from "./timerHelper";

import { TimeFormat as ClockTimeFormat } from "@/components/TimePicker/types";
import { EventTimeFormat } from "@/types/qr";

dayjs.extend(localeData);

export const convertTimeFormatToClockFormat = (
  timeFormat: EventTimeFormat
): ClockTimeFormat => {
  const convertMap = {
    "24-HOURS": "24",
    "AM-PM": "AM/PM",
  };
  return convertMap[timeFormat as string];
};

export const getPreviewFormattedDateString = (date: string): string => {
  if (!date || !dayjs(date).isValid()) return "";

  const objectDay = dayjs(date);

  const dayName = dayjs.weekdays()[objectDay.get("day")];
  const month = dayjs.months()[objectDay.get("month")];
  const dayNumber = objectDay.get("date");
  const year = objectDay.get("year");

  return `${dayName}, ${month} ${dayNumber}, ${year}`;
};

export const getPreviewFormattedTimeString = (
  startTime: string,
  endTime: string,
  format: EventTimeFormat = "AM-PM"
): string => {
  if (!startTime && !endTime) return "";

  const formattedStartTime =
    formatToBusinessHoursInput(
      startTime,
      convertTimeFormatToClockFormat(format)
    )?.toUpperCase() || "";
  const formattedEndTime =
    formatToBusinessHoursInput(
      endTime,
      convertTimeFormatToClockFormat(format)
    )?.toUpperCase() || "";

  return `${formattedStartTime} - ${formattedEndTime}`;
};
