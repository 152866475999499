import { UserContextProps } from "@/contexts/UserContext/types";
import { getRemainingFreeTrialDays, isUserFreeTrial, isUserNotAuthorized } from "@/services/UserService/UserService";

export const getAlertContent = (content: any, userContext: UserContextProps): string => {
    if(isUserFreeTrial(userContext) || isUserNotAuthorized(userContext)) {
        if (isUserNotAuthorized(userContext)) {
            return content.DashboardAlertTextTrialExpired;
        } else {
            const remainingFreeTrialDays = getRemainingFreeTrialDays(userContext);
            let remainingDaysContent = "";

            switch(true) {
                case remainingFreeTrialDays > 1:
                    remainingDaysContent = `${content.DashboardAlertTextTrialActive1_2}${remainingFreeTrialDays} ${content.DashboardAlertTextTrialActive3_1}`; // in 2 days
                    break;
                case remainingFreeTrialDays === 1:
                    remainingDaysContent =  `${content.DashboardAlertTextTrialActive3_3}`; // today
                    break;
                default:
            }

            return `${content.DashboardAlertTextTrialActive1}${remainingDaysContent}${content.DashboardAlertTextTrialActive2}`;
        } 
    } else {
        return content.DashboardAlertText;
    }
}