import React from "react";
import { OptionProps } from "./types";

export const Option = ({ children, onClick, key, value }: OptionProps) => {
  const onClickHandler = () => {
    onClick(value);
  };

  return (
    <div
      className="panel__option"
      key={key}
      onClick={onClickHandler}>
      {children}
    </div>
  );
};
