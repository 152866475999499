import React from "react";
import { FiChevronDown } from "react-icons/fi";

import { BusinessHourInputProps } from "./types";

import "./styles.scss";

export function BusinessHourInput(props: BusinessHourInputProps) {
  const { defaultValue, placeholder, isEnabled, onClick } = props;

  return (
    <div
      onClick={(e) => onClick && onClick(e)}
      className={`Business__input${!isEnabled ? " Business__input--disabled" : ""}`}>
      <div>
        {defaultValue ? (
          <span className="Business__input__default">{defaultValue}</span>
        ) : (
          <span className="Business__input__placeholder">{placeholder}</span>
        )}
      </div>
      <FiChevronDown />
    </div>
  );
}
