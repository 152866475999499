import React from "react";

import {
  HOUR24_RADIUS_CONSTANT,
  NUMBER_SIZE_PERCENT,
  RADIUS_CONSTANT,
  degreesToRadians,
  getLimit,
  getTimeParams,
} from "@/utils/timerHelper";

import { IHand } from "./types";

export const Hand: React.FC<IHand> = (props) => {
  const { type = "hour", diameter, selectedTime } = props;

  const timeParams = getTimeParams(type);

  const divider = 360 / getLimit(type, timeParams.limit);
  const angle = degreesToRadians(selectedTime === undefined ? 360 : selectedTime * divider);
  const radius = diameter / 2;
  const lineProps = {
    x1: radius + radius * RADIUS_CONSTANT * Math.sin(angle) * 0,
    y1: radius - radius * RADIUS_CONSTANT * Math.cos(angle) * 0,
    x2:
      radius +
      radius *
        RADIUS_CONSTANT *
        Math.sin(angle) *
        (type === "hour24" &&
        (selectedTime > 12 || selectedTime === 0 || selectedTime === undefined)
          ? HOUR24_RADIUS_CONSTANT + NUMBER_SIZE_PERCENT
          : 0.9),
    y2:
      radius -
      radius *
        RADIUS_CONSTANT *
        Math.cos(angle) *
        (type === "hour24" &&
        (selectedTime > 12 || selectedTime === 0 || selectedTime === undefined)
          ? HOUR24_RADIUS_CONSTANT + NUMBER_SIZE_PERCENT
          : 0.9),
  };

  return (
    <svg
      height={diameter}
      width={diameter}
      style={{ position: "absolute" }}>
      <line
        style={{ position: "absolute", strokeWidth: 2, stroke: "#1775d3" }}
        {...lineProps}
      />
    </svg>
  );
};
