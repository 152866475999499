import classNames from "classnames";

import { DividerProps } from "./types";

import "./styles.scss";

export const Divider = ({ spacing, className, orSeparator }: DividerProps) => {
  const classnames = classNames("", {
    [className]: className,
    [`u-spacing-${spacing}`]: spacing,
    "divider__or-separator": orSeparator,
  });

  return <hr className={`divider ${classnames}`} />;
};
