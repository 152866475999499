import React from "react";

import { Button } from "@/components/Button";
import { Typography } from "@/components/Typography";
import { TimerInput } from "@/components/TimerInput";
import { ITimeTimerInput, TimeFormatType } from "@/components/TimerInput/types";
import { AnalogicalTimerSelector } from "./AnalogicalTimerSelector";
import { ThemePickerProps, TimeType } from "./types";
import { TimerView } from "./TimerView";

import "./styles.scss";

export const TimePicker = (props: ThemePickerProps) => {
  const { viewType = "analogical", label, time, timeFormat, onChangeTime, onCancelChange } = props;

  const [selectedTime, setSelectedTime] = React.useState(time);
  const [selectedViewType, setSelectedViewType] = React.useState(viewType);
  const [selectedTimeType, setSelectedTimeType] = React.useState<TimeType>("hour");
  const [selectedTimeFormat, setSelectedTimeFormat] = React.useState<TimeFormatType>(
    time.type === "PM" ? "PM" : "AM"
  );

  const renderViewType = () => {
    let view: JSX.Element;

    if (selectedViewType === "analogical") {
      if (selectedTimeType === "hour") {
        view = (
          <div className="timer__analogical">
            <AnalogicalTimerSelector
              type="hour"
              timeFormat={timeFormat === "24" ? "hour24" : "hour"}
              time={selectedTime}
              onChangeTimeType={(timeType) => setSelectedTimeType(timeType)}
              onTimeChange={(time, timeType) => (
                setSelectedTime(time), setSelectedTimeType(timeType)
              )}
            />
          </div>
        );
      } else {
        view = (
          <div className="timer__analogical">
            <AnalogicalTimerSelector
              type="minutes"
              time={selectedTime}
              onChangeTimeType={(timeType) => setSelectedTimeType(timeType)}
              onTimeChange={(time, timeType) => (
                setSelectedTime(time), setSelectedTimeType(timeType)
              )}
            />
          </div>
        );
      }
    } else {
      view = (
        <TimerInput
          type={timeFormat === "24" ? "24" : selectedTimeFormat}
          time={selectedTime}
          onTimeChange={(time, timeType) => (setSelectedTime(time), setSelectedTimeType(timeType))}
        />
      );
    }

    return view;
  };

  const formatResponseTime = (): ITimeTimerInput => {
    return {
      type: timeFormat === "24" ? "24" : selectedTimeFormat,
      hour: selectedTime.hour || "00",
      minutes: selectedTime.minutes || "00",
    };
  };

  return (
    <div>
      <div className="timer">
        {label && (
          <Typography
            classname="timer__label"
            Tag="p">
            {label}
          </Typography>
        )}
        <TimerView
          time={selectedTime}
          selectedTimeType={selectedTimeType}
          timeFormat={timeFormat}
          viewType={selectedViewType}
          AMPMTimeFormat={selectedTimeFormat}
          onChangeHourMinutes={(timeType) => setSelectedTimeType(timeType)}
          onChangeAMPMTimeFormat={(format) => setSelectedTimeFormat(format)}
          onChangeViewType={(viewType) => setSelectedViewType(viewType)}
        />
        {renderViewType()}
        <div className="timer__buttons">
          <Button
            label="Cancel"
            tertiary
            onClick={() => onCancelChange()}
          />
          <Button
            label="Save"
            onClick={() => onChangeTime(formatResponseTime())}
          />
        </div>
      </div>
    </div>
  );
};
