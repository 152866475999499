import { map } from "lodash";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import Components from "./Components";
import { isFieldFormLoading } from "@/utils/formHelper";

export const FormFields = ({
  fields,
  methods,
  isLoading,
  isDisabled,
  isAuth,
}: {
  fields: any;
  methods: UseFormReturn;
  isLoading: boolean | string[];
  isDisabled?: boolean;
  isAuth: boolean;
}) => {
  React.useEffect(() => {
    isAuth ? document.getElementById(fields[0].id)!.focus() : null;
  }, []);

  return (
    <React.Fragment>
      {map(fields, (item, i) => {
        const Component = Components[item["component"]];
        const itemFields = {
          formfields: item.formFields,
          ...item,
        };
        delete itemFields.formFields;

        if (!item.name) {
          if (item.formFields) {
            return (
              <Component
                key={i}
                {...itemFields}
                data-qa={item.data_qa}
                dataTestId={item.data_testid}
                defaultValue={item.defaultValue}
              >
                <FormFields
                  fields={item.formFields}
                  methods={methods}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  isAuth={isAuth}
                />
              </Component>
            );
          } else {
            return (
              <Component
                key={i}
                {...itemFields}
                dataQA={item.data_qa}
                dataTestId={item.data_testid}
                defaultValue={item.defaultValue}
              >
                {item.children}
              </Component>
            );
          }
        }

        const errors = methods.formState.errors;
        const isFieldLoading = isFieldFormLoading(item.name, isLoading);
        const fieldState = methods.getFieldState(item.name);

        return (
          <Component
            {...itemFields}
            key={i}
            dataQA={item.data_qa}
            dataTestId={item.data_testid}
            error={errors[item.name]}
            loading={isFieldLoading}
            disabled={isFieldLoading || isDisabled || itemFields.disabled}
            icon={isFieldLoading}
            errorMessage={errors[item.name]?.message}
            success={fieldState?.isTouched && !fieldState?.error}
            defaultValue={item.defaultValue}
          />
        );
      })}
    </React.Fragment>
  );
};
