import React from "react";
import { AddedItemPreviewProps } from "./types";
import { ReactSVG } from "react-svg";
import ReactPlayer from "react-player";


export const AddedFileItemPreview = ({ fileType, fileUrl, fileName }: AddedItemPreviewProps) => {
  if (fileType === "pdf") {
    return (
      <ReactSVG
        className="FilesUpload__files__svg"
        src="/svg/icon-pdf.svg"
      />
    );
  }

  if (fileType === "multimedia") {
    return (
      <div className="FilesUpload__files__item__player">
        <ReactPlayer
          width="100%"
          height="100%"
          style={{ cursor: "default", pointerEvents: "none" }}
          controls={false}
          url={fileUrl}
          config={{
            youtube: {
              playerVars: { controls: 0, disablekb: 1, modestbranding: 1 },
            },
          }}
        />
      </div>
    );
  }

  return (
    <img
      className="FilesUpload__files__item__image"
      src={fileUrl}
      alt={fileName}
    />
  );
};
