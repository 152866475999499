import React from "react";

import { ICircle } from "./types";

import "./styles.scss";

export const Circle: React.FC<ICircle> = (props) => {
  const { children } = props;

  return <div className="circle">{children}</div>;
};
