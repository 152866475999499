import React from "react";
import { IoMdSearch } from "react-icons/io";
import { SearchInputProps } from "./types";

export const SearchInput = ({ dataQa, onChange }: SearchInputProps) => {
  return (
    <div
      className="panel__search"
      data-qa={dataQa}>
      <input
        onChange={onChange}
        className="panel__search-input"
      />
      <IoMdSearch className="panel__search-icon" />
    </div>
  );
};
