import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useRef,
} from "react";
import classNames from "classnames";
import { SelectorInput } from "./SelectorInput";
import { OptionsPanel } from "./OptionsPanel";
import { Option } from "./Option";
import { SearchInput } from "./SearchInput";
import {
  SearchSelectContextType,
  SearchSelectOptionsProps,
  SearchSelectProps,
} from "./types";

import "./styles.scss";

const SearchSelectContext = createContext<SearchSelectContextType>(undefined);

export function SearchSelect({ children, fluid, onChange }: SearchSelectProps) {
  const ref = useRef(null);
  const [open, setIsOpen] = useState(false);

  const classes = classNames("search-select", {
    "search-select--fluid": fluid,
    "search-select--active": open,
  });

  const tooglePanel = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        tooglePanel();
      }
    }
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <SearchSelectContext.Provider value={{ open, tooglePanel, onChange }}>
      <div className={classes} ref={ref}>
        {children}
      </div>
    </SearchSelectContext.Provider>
  );
}

SearchSelect.Input = function SelectInput(props) {
  const Context = useContext(SearchSelectContext);

  const onClickHandler = () => {
    Context?.tooglePanel();
  };

  return <SelectorInput onClick={onClickHandler} {...props}></SelectorInput>;
};

SearchSelect.Options = function SearchSelectOptions({
  children,
  onSearch,
  dataQa,
}: SearchSelectOptionsProps) {
  const Context = useContext(SearchSelectContext);

  const classes = classNames("panel", {
    "panel--visible": Context?.open,
  });

  return (
    <div className={classes} data-qa={dataQa}>
      <SearchInput onChange={onSearch} dataQa={`${dataQa}-search-input`} />
      <OptionsPanel>{children}</OptionsPanel>
    </div>
  );
};

SearchSelect.Option = function SearchSelectOption(props) {
  const Context = useContext(SearchSelectContext);

  const onClickHandler = (args) => {
    Context.onChange(args);
    Context.tooglePanel();
  };

  return <Option {...props} onClick={onClickHandler} />;
};
