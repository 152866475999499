import React, { useEffect, useState } from "react";
import { AddedFileItemPreview } from "./AddedFileItemPreview";
import { Typography } from "@components/Typography";
import { Button } from "@components/Button";
import { FaTrash } from "react-icons/fa";
import { AddedItemProps } from "@components/FilesUpload/types";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { LoadingBarContext } from "@/contexts/LoadingBarContext";

export const AddedFileItem = ({
  fileId,
  fileType,
  fileName,
  fileSize,
  fileUrl,
  onRemoveItem,
  loading,
  disabled,
}: AddedItemProps) => {
  const FileUploaderContext = React.useContext(LoadingBarContext);
  const progress = FileUploaderContext.getProgress(Number(fileId));

  const [progressState, setProgressState] = useState(progress);

  useEffect(() => {
    setProgressState(progress);
    return () => setProgressState(0);
  }, [progress]);

  const onRemoveItemHandler = () => {
    FileUploaderContext.removeProgress(Number(fileId));
    typeof onRemoveItem === "function" && onRemoveItem(fileId);
  };

  const classes = classNames("FilesUpload__files__item", {
    "FilesUpload__files__item--loading": loading,
  });

  return (
    <div
      className={classes}
      style={{
        background: `linear-gradient(90deg, rgba(232,242,252,1) 0%, rgba(232,242,252,1) ${progressState}%, rgba(255,255,255,1) ${
          progressState > 0 ? progressState + 5 : 0
        }%)`,
      }}>
      {loading && (
        <ReactSVG
          className="loader"
          src="/svg/loading-icon.svg"
        />
      )}
      <AddedFileItemPreview
        fileType={fileType}
        fileName={fileName}
        fileUrl={fileUrl}
      />
      <div className="FilesUpload__files__item__content">
        <Typography
          Tag={"p"}
          classname="u-bold">
          {fileName}
        </Typography>
        {fileSize}
      </div>
      <Button
        icon={<FaTrash />}
        type="button"
        tertiary
        square
        disabled={loading || disabled}
        onClick={onRemoveItemHandler}
      />
    </div>
  );
};
