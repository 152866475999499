import React from "react";

import { Circle } from "./Circle";
import { Time } from "./Time";
import { Hand } from "./Hand";

import {
  HOUR24_RADIUS_CONSTANT,
  NUMBER_SIZE_PERCENT,
  getLimit,
  getMouseAngle,
  getRadius,
  getTimeParams,
} from "@/utils/timerHelper";

import { IAnalogicalTimer, ITimeType } from "./types";

export const AnalogicalTimer: React.FC<IAnalogicalTimer> = (props) => {
  const { type = "hour", duration, onChangeTimeType, onDurationChange } = props;

  const [diameter, setDiameter] = React.useState(500);
  const [isTouching, setIsTouching] = React.useState(false);

  const view = React.useRef(null);

  const onResize = () => {
    if (view) {
      const { width, height } = view.current.getBoundingClientRect();
      setDiameter(Math.min(width, height));
    }
  };

  const formatNumber = (
    type: ITimeType,
    originalNumber: number,
    centerX: number,
    centerY: number,
    x: number,
    y: number
  ) => {
    let number = originalNumber;

    if (type === "hour24") {
      const radius = getRadius(type, number, diameter / 2);
      const distanceToCenter = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2) / radius;
      number =
        distanceToCenter >= HOUR24_RADIUS_CONSTANT + 0.2 + NUMBER_SIZE_PERCENT
          ? number
          : number + 12;

      number =
        distanceToCenter <= HOUR24_RADIUS_CONSTANT + 0.2 + NUMBER_SIZE_PERCENT && number === 12
          ? 0
          : distanceToCenter >= HOUR24_RADIUS_CONSTANT + 0.2 + NUMBER_SIZE_PERCENT && number === 0
          ? 12
          : number;
      number = number === 24 ? 0 : number;
    } else if (type === "minutes") {
      number = number === 60 ? 0 : number;
    } else if (type === "hour") {
      number = number === 0 ? 12 : number;
    }

    return number;
  };

  const updatePosition = (e: any) => {
    if (!e.pageX && !e.changedTouches) return;

    const rect = view.current.getBoundingClientRect();

    const rectCenterX = rect.left + rect.width / 2;
    const rectCenterY = rect.top + rect.height / 2;

    const mouseX = e.clientX || e.touches[0].clientX;
    const mouseY = e.clientY || e.touches[0].clientY;

    const mouseAngle = getMouseAngle(rectCenterX, rectCenterY, mouseX, mouseY);

    const timeParams = getTimeParams(type);
    const divider = 360 / getLimit(type, timeParams.limit);

    let number = Math.round(mouseAngle / divider);

    number = formatNumber(type, number, rectCenterX, rectCenterY, mouseX, mouseY);

    if (number !== duration) {
      onDurationChange(number);
    }
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement | TouchEvent>
  ) => {
    updatePosition(e);
    setIsTouching(true);
  };

  const handleMouseUp = () => {
    setIsTouching(false);
    onChangeTimeType && onChangeTimeType(type === "minutes" ? "hour" : "minutes");
  };

  const handleMouseMove = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement | TouchEvent>
  ) => {
    if (isTouching) {
      updatePosition(e);
    }
  };

  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <div
      data-testid="analogical-timer"
      ref={view}
      style={{ width: "220px", height: "220px" }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}>
      <Circle>
        <Hand
          diameter={220}
          type={type}
          selectedTime={duration}
        />
        <Time
          diameter={220}
          type={type}
          selectedTime={duration}
        />
      </Circle>
    </div>
  );
};
