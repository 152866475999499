import React from "react";
import { OptionsPanelProps } from "./types";

export const OptionsPanel = ({ children, ...rest }: OptionsPanelProps) => {
  return (
    <div
      {...rest}
      className="panel__options">
      <div className="panel__options-inner">{children}</div>
    </div>
  );
};
