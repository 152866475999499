import React, { useState } from "react";

import PickerItem from "./PickerItem";
import { Tooltip } from "../Tooltip";

import { PickerProps } from "./types";

import { ConnectForm } from "@/utils/formHelper";

import "./styles.scss";

export function Picker({
  title,
  options,
  multiple,
  id,
  dataQA,
  defaultValue,
  className,
  onClick,
  selector,
}: PickerProps) {
  let defaultOption =
    typeof options[0] === "string" ? options[0] : options[0].item;
  const [selectedOption, setSelectedOption] = useState<string | string[]>(
    defaultValue || (multiple ? [] : defaultOption)
  );

  React.useEffect(() => {
    if (onClick) onClick(selectedOption);
  }, [selectedOption]);

  return (
    <ConnectForm>
      {title && <p>{title}</p>}
      <ul
        data-testid="Picker"
        data-qa={dataQA}
        className={`Picker${className ? " " + className : ""}`}
        id={id}
      >
        {options.map((option, i: number) => {
          const formattedOption =
            typeof option === "string" ? option : option.item;
          if (option.tooltip) {
            return (
              <React.Fragment key={i}>
                <div>
                  <Tooltip
                    text={option.tooltip?.text}
                    position={option.tooltip?.position}
                    id={`${i}`}
                  >
                    <PickerItem
                      option={formattedOption}
                      id={i}
                      multiple={multiple}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      selector={selector}
                    />
                  </Tooltip>
                </div>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={i}>
              <PickerItem
                option={formattedOption}
                id={i}
                multiple={multiple}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selector={selector}
              />
            </React.Fragment>
          );
        })}
      </ul>
    </ConnectForm>
  );
}
