"use client";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import classNames from "classnames";

import { AccordionProps } from "./types";

import "./styles.scss";

export const Accordion = ({
  title,
  children,
  className,
  dark,
  first,
  spacing,
  initialState = true,
  isActiveItem,
  toggle,
  allClosed,
  hideArrow,
  icon,
  iconTop,
  onClick,
  sidebarCollapsed,
  accordionIndex,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const classnames = classNames(`accordion`, {
    "accordion--isOpen": !allClosed ? isOpen : isActiveItem,
    [`accordion--${className}`]: className,
    "accordion--dark": dark,
    "accordion--iconTop": iconTop,
    "accordion--dark--first": first && dark,
    [`u-spacing-${spacing}`]: spacing,
  });
  const classnamesAccordionTitle = classNames(`accordion__title`, {
    "accordion__title--isOpen": !allClosed ? isOpen : isActiveItem,
  });

  const handleToggle = (accordionIndex) => {
    if (sidebarCollapsed && onClick) onClick();
    allClosed ? toggle() : setIsOpen(!isOpen);

    /* scroll to open accordion */
    if (accordionIndex) {
      const indexAcc =
        accordionIndex === 0 ? accordionIndex : accordionIndex - 1;

      if (!isActiveItem) {
        const activeElem = document.getElementsByClassName("accordion__header")[
          indexAcc
        ] as Element;
        activeElem?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        const firstElem = document.getElementsByClassName("accordion")[0]
          .parentElement as Element;
        firstElem?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  };

  useEffect(() => {
    if (sidebarCollapsed && isActiveItem) toggle();
  }, [sidebarCollapsed]);

  return (
    <div data-testid="accordion" className={classnames}>
      <div
        data-testid="accordion-header"
        className="accordion__header"
        onClick={() => handleToggle(accordionIndex)}
        tabIndex={0}
      >
        {icon && <div className="accordion__icon">{icon}</div>}
        <div data-testid="accordion-title" className={classnamesAccordionTitle}>
          {title}
        </div>
        {!hideArrow && (
          <div data-testid="accordion-button" className="accordion__button">
            <FiChevronDown />
          </div>
        )}
      </div>
      <div data-testid="accordion-body" className="accordion__body">
        {children}
      </div>
    </div>
  );
};
