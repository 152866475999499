import React from "react";

import {
  NUMBER_SIZE_PERCENT,
  degreesToRadians,
  getLimit,
  getRadius,
  getTimeParams,
} from "@/utils/timerHelper";

import { ITime, ITimeNumbers, ITimeType } from "./types";

const getNumbers = (props: ITimeNumbers): number[] => {
  const { start, step, limit } = props;

  const numberList = (numbers: number[]) => {
    const lastNumber = numbers[numbers.length - 1];
    const nextNumber = lastNumber + step;

    if (nextNumber > limit) return numbers;

    return numberList([...numbers, nextNumber]);
  };

  return numberList([start]);
};

const getDegrees = (type: ITimeType, number: number, divider: number) => {
  let degreesValue = number;

  if (type === "hour24" && number > 12) {
    degreesValue = number - 12;
  }

  return degreesValue * divider;
};

const getFontColor = (type: ITimeType, number: number, selectedTime: number) => {
  let color = "black";

  if (number === selectedTime) {
    color = "white";
  } else if (type === "hour24" && (number > 12 || number === 0)) {
    color = "grey";
  } else if (type === "minutes" && number % 5) {
    color = "rgba(0, 0, 0, 0)";
  }

  return color;
};

const getFontSize = (type: ITimeType, number: number, numberSize: number) => {
  let size = numberSize;

  if (type === "hour24" && number > 12) {
    size = numberSize / 1.2;
  }

  return size;
};

const getFormattedNumber = (type: ITimeType, number: number) => {
  let formattedNumber: number | string = number;

  if (type === "minutes") {
    if (number % 5) {
      formattedNumber = "•";
    } else {
      if (number === 60) {
        formattedNumber = "00";
      }
    }
  } else if (type === "hour24" && number === 24) {
    formattedNumber = "00";
  }

  return formattedNumber;
};

export const Time = (props: ITime): JSX.Element => {
  const { type = "hour", diameter, selectedTime } = props;

  const center = diameter / 2;

  const timeParams = getTimeParams(type);
  const timerNumbers = getNumbers(timeParams);
  const numberSize = diameter * NUMBER_SIZE_PERCENT;

  const movementDistance = numberSize / 2;

  const numbers = timerNumbers.map((number) => {
    const radius = getRadius(type, number, center);

    const divider = 360 / getLimit(type, timeParams.limit);
    const angle = degreesToRadians(getDegrees(type, number, divider));

    const numberLeftPosition = center + radius * Math.sin(angle) - movementDistance;
    const numberTopPosition = center - radius * Math.cos(angle) - movementDistance;

    const formattedNumberForColor =
      (type === "minutes" && number === 60) || (type === "hour24" && number === 24) ? 0 : number;

    return (
      <div
        key={number}
        data-testid={`analogical-timer-numbers-${number}`}
        style={{
          position: "absolute",
          color: getFontColor(type, formattedNumberForColor, Number(selectedTime || "00")),
          backgroundColor:
            formattedNumberForColor === Number(selectedTime || "00") ? "#1775d3" : "transparent",
          borderRadius: "50%",
          textAlign: "center",
          verticalAlign: "middle",
          cursor: "pointer",
          userSelect: "none",
          top: numberTopPosition - (numberSize * 0.5) / 2,
          left: numberLeftPosition - (numberSize * 0.5) / 2,
          fontSize: getFontSize(type, number, numberSize),
          width: numberSize + numberSize * 0.5,
          height: numberSize + numberSize * 0.5,
          lineHeight: `${numberSize + numberSize * 0.5}px`,
          zIndex: type === "minutes" && number % 5 ? 1 : 2,
        }}>
        {getFormattedNumber(type, number)}
      </div>
    );
  });

  return <React.Fragment>{numbers}</React.Fragment>;
};
