import React from "react";
import classNames from "classnames";
import { FiAlertCircle } from "react-icons/fi";

import { CheckboxProps } from "./types";
import "./styles.scss";
import { ConnectForm } from "@/utils/formHelper";

export const Checkbox = ({
  id,
  dataQA,
  name,
  label,
  error,
  errorMessage,
  success,
  disabled,
  defaultValue,
  className,
  onChange,
}: CheckboxProps) => {
  const classnames = classNames(`Checkbox`, {
    [`Checkbox--${className}`]: className,
    "Checkbox--error": error,
    "Checkbox--success": success,
    "Checkbox--disabled": disabled,
  });

  return (
    <div data-testid="Checkbox" data-qa={dataQA} className={classnames}>
      <ConnectForm>
        <div className="Checkbox-container">
          <input
            type="checkbox"
            name={name}
            defaultChecked={defaultValue}
            className="Checkbox__item"
            id={id}
            onChange={(e) => onChange && onChange(e)}
          />
          <span className="checkmark"></span>
        </div>
      </ConnectForm>
      <label
        data-testid="Checkbox__label"
        htmlFor={id}
        dangerouslySetInnerHTML={{ __html: label }}
      ></label>
      {error && (
        <p className="Checkbox__error-message body-s">
          <FiAlertCircle />
          {errorMessage}
        </p>
      )}
    </div>
  );
};
