import React from "react";
import { ReactSVG } from "react-svg";
import { includes, filter } from "lodash";

import { PickerOptionProps } from "./types";

import "./styles.scss";

export default function PickerItem({
  option,
  id,
  selectedOption,
  setSelectedOption,
  multiple,
  selector,
}: PickerOptionProps) {
  const onSelectOption = (option: string) => {
    if (multiple) {
      const isOptionSelected = includes(selectedOption, option);

      isOptionSelected
        ? setSelectedOption(
            filter(selectedOption, (targetOption) => targetOption !== option)
          )
        : setSelectedOption([...selectedOption, option]);
    } else {
      setSelectedOption(option);
    }
  };

  return (
    <li
      data-option={option}
      data-qa={`${option}-button`}
      className={`Picker__item ${
        multiple
          ? includes(selectedOption, option)
            ? "Picker__item--active"
            : ""
          : selectedOption === option
          ? "Picker__item--active"
          : ""
      }`}
      key={id}
      onClick={() => onSelectOption(option)}
    >
      {selector && (
        <ReactSVG
          className="Picker__icon"
          src={`/svg/${selector}-${option}.svg`}
        />
      )}
    </li>
  );
}
