import React from "react";
import classNames from "classnames";
import { FiAlertCircle } from "react-icons/fi";

import { formattedTime, inputIndexChanged } from "@/utils/timerHelper";

import { ITimeTimerInput, ITimeTimerInputType, ITimerInput } from "./types";
import { TimeType } from "@/components/TimePicker/types";

import "./styles.scss";

export const TimerInput: React.FC<ITimerInput> = (props) => {
  const { type = "24", error, className, time, onTimeChange } = props;

  const classnames = classNames(`Input`, {
    [`Input--${className}`]: className,
    "Input--error": error,
  });

  const [errorTime] = React.useState(error);
  const [inputTime, setInputTime] = React.useState<ITimeTimerInput>(time);
  const [initialValue, setInitialValue] = React.useState(formattedTime(time, type));
  const [changedTimeType, setChangedTimeType] = React.useState<TimeType>("hour");

  const getPlaceholder = () => {
    let placeholderValue = "08:00";

    if (type === "AM") {
      placeholderValue = "08:00 AM";
    } else if (type === "PM") {
      placeholderValue = "08:00 PM";
    }

    return placeholderValue;
  };

  const setFormattedTime = (type: ITimeTimerInputType, userTime: string) => {
    const typePrefix = type === "PM" ? "P" : "A";

    const [hour, minutes] = userTime.split(":");
    const formatMinutes = minutes.slice(0, 2).trim();
    setInputTime({ hour, minutes: formatMinutes === typePrefix ? "" : formatMinutes });
    setInitialValue(formattedTime({ hour, minutes }, type));
  };

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userTime = e.target.value;

    const previousValue = initialValue;
    const changeIndex = inputIndexChanged(previousValue, userTime);

    setChangedTimeType(changeIndex >= 0 && changeIndex <= 2 ? "hour" : "minutes");

    if (type === "24") {
      if (/^([01]?[0-9]?|2[0-3]?):([0-5][0-9]?)?$/.test(userTime)) {
        const [hour, minutes] = userTime.split(":");
        setInputTime({ hour, minutes });
        setInitialValue(formattedTime({ hour, minutes }, type));
      }
    } else {
      if (type === "AM" && /^(0?[1-9]?|1[0-2]?):([0-5][0-9]?)?( AM)?$/.test(userTime)) {
        setFormattedTime(type, userTime);
      } else if (type === "PM" && /^(0?[1-9]?|1[0-2]?):([0-5][0-9]?)?( PM)?$/.test(userTime)) {
        setFormattedTime(type, userTime);
      }
    }
  };

  React.useEffect(() => {
    onTimeChange({ ...inputTime, type }, changedTimeType);
  }, [inputTime]);

  return (
    <div className={classnames}>
      <div className="Input__wrapper">
        <input
          data-testid="Input__element"
          className="Input__element"
          placeholder={getPlaceholder()}
          onClick={(e) => e.stopPropagation()}
          onChange={onHandleChange}
          autoComplete="off"
          maxLength={type === "24" ? 5 : 8}
          value={formattedTime(inputTime, type)}
        />
      </div>
      {errorTime && errorTime.value && (
        <p
          data-testid="Input__error-message"
          className="Input__error-message body-s">
          <FiAlertCircle />
          {errorTime.message}
        </p>
      )}
    </div>
  );
};
