import React from "react";
import { ReactSVG } from "react-svg";

import { ListPickerItemProps } from "./types";
import "./styles.scss";

export default function ListPickerItem({
  item,
  id,
  addRemoveOption,
  selectedOptions,
}: ListPickerItemProps) {
  return (
    <li
      className={`ListPicker__item ${item.mod ? "ListPicker__item--mod" : ""} ${
        selectedOptions.find((e) => e.id === item.id) ? "ListPicker__item--active" : ""
      }`}
      data-qa={item.data_qa}
      key={id}
      onClick={() => addRemoveOption(item)}
      aria-label={item.name}>
      <ReactSVG
        className="ListPicker__icon"
        src={`/svg/icon-${item.id}${item.mod ? item.mod : ""}.svg`}
      />
    </li>
  );
}
