import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Timezone } from "@/services/TimezonesService/types";

dayjs.extend(utc);
dayjs.extend(timezone);

export class TimezonesService {
  static timezones: Timezone[] = this._createTimezones();
  static localTimezone: Timezone = this.getLocalTimezone();

  private static _createTimezones() {
    const timezones = [];
    const now = dayjs().format("YYYY-MM-DD");

    const timeZoneList = Intl.supportedValuesOf("timeZone");

    for (const tz of timeZoneList) {
      const offset = dayjs.utc(now).tz(tz).format("Z");

      const displayName = `${this.prettifyTimezone(tz)} - (GMT${offset})`;
      const displayOffset = `(GMT${offset})`;

      timezones.push({ timezone: tz, offset, displayName, displayOffset });
    }

    return timezones;
  }

  static getLocalTimezone(): Timezone {
    const localTZ = dayjs.tz.guess();

    const timezone = this.timezones.find(
      (tz) => tz.timezone.toLowerCase() === localTZ.toLowerCase()
    );

    return timezone;
  }

  static prettifyTimezone(tz: string): string {
    return tz.replaceAll("/", ", ").replaceAll("_", " ");
  }

  static isValid(timezone: string): boolean {
    if (!timezone || typeof timezone !== "string") return false;
    return !!this.timezones.find(
      (tz) => tz.timezone.toLowerCase() === timezone.toLowerCase()
    );
  }

  static convertDateFromTimezoneToLocalTimezone(
    date: string | Date,
    time: string,
    timezone: string
  ): undefined | string {
    if (!date || !time) return undefined;

    let dateString = date;
    if (date instanceof Date) {
      dateString = dayjs(date).format("YYYY-MM-DD");
    }

    const utcDate = dayjs.utc(dateString);
    const [hour, min] = time.split(":");

    const originTimezone = this.isValid(timezone)
      ? timezone
      : this.localTimezone.timezone;
    const originalDate = utcDate
      .hour(Number(hour))
      .minute(Number(min))
      .tz(originTimezone, true);

    const localDate = originalDate
      .tz(TimezonesService.localTimezone.timezone)
      .format("YYYY-MM-DDTHH:mm:ssZ");

    return localDate;
  }

  static getGMTOffset(tz: string): string {
    const timezone = this.timezones.find((_tz) => _tz.timezone === tz);

    if (!timezone) return "";

    return timezone.displayOffset;
  }
}
